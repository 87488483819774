import '../app/globals.css';

import Link from 'next/link';

import { ButtonV2, TextV2 } from '@layr-labs/eigen-kit/react';

import { ABCRepro, ibmPlexMono } from 'fonts/localFont';

export default function Custom404() {
  return (
    <div
      className={`${ABCRepro.className} ${ABCRepro.variable} ${ibmPlexMono.variable} h-screen bg-lightblue-100 text-blue-700`}
    >
      <div className="flex h-4/5 flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <TextV2 intent="DisplayM" className="mb-4 text-center">
            404 Page Not Found
          </TextV2>

          <Link href="/">
            <ButtonV2 intent="secondary">Return Home</ButtonV2>
          </Link>
        </div>
      </div>
    </div>
  );
}
